import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"card-header border-0 pt-5\"><h3 class=\"card-title align-items-start flex-column\"><span class=\"card-label fw-bolder fs-3 mb-1\">Recent Orders</span><span class=\"text-muted fw-bold fs-7\">More than 500 new orders</span></h3><div class=\"card-toolbar\" data-kt-buttons=\"true\"><a class=\"btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1\" id=\"kt_charts_widget_8_year_btn\">Year</a><a class=\"btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1\" id=\"kt_charts_widget_8_month_btn\">Month</a><a class=\"btn btn-sm btn-color-muted btn-active btn-active-primary px-4\" id=\"kt_charts_widget_8_week_btn\">Week</a></div></div>", 1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_apexchart, {
        type: "area",
        options: _ctx.options,
        series: _ctx.series
      }, null, 8, ["options", "series"])
    ])
  ], 2))
}